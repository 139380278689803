



















































































































































































import Component, { mixins } from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniModal from '@/components/layout/SygniModal.vue';
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniMultiSelect from "@/components/inputs/SygniMultiSelect.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniRangeInput from "@/components/inputs/SygniRangeInput.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniTabs from '@/components/layout/SygniTabs.vue';
import SygniButtonsGroup from "@/components/layout/SygniButtonsGroup.vue";
import { Filter, FilterFunctions, FilterHelpers, FilterMixin, FilterOperators } from "@/shared/mixins/FilterMixin";
import SygniCheckableButton from "@/components/buttons/SygniCheckableButton.vue";
import { Prop, Watch } from 'vue-property-decorator';
import { usedCurrencies } from '@/shared/consts';

@Component({
  components: {
    SygniModal,
    SygniCheckableButton,
    SygniRangeInput,
    SygniButtonsGroup,
    SygniDatePicker,
    SygniContainerTitle,
    SygniRoundedButton,
    SygniSquareButton,
    SygniSelect,
    SygniInput,
    SygniMultiSelect,
    SygniTabs,
  },
})

export default class StatementFilters extends mixins(FilterMixin) {
  @Prop({ default: null }) type: string;
  itemsUrl: string = 'bank-statement/statement';
  toggleFilters: boolean = false;
  
  areAccountNumberOptionsLoading: boolean = false;
  accountNumberOptions: any = [];
  bankAccountNumberSearch: string = ''
  selectedAccountNumbers: any[] = []
  selectedStatementCurrencies: any[] = []
  selectedStatusOptions: any[] = []
  selectedTypeOptions: any[] = []
  selectedPaymentStatusOptions: any[] = []
  bankAccountNumber: string = null
  usedCurrencies: any = usedCurrencies;
  statusOptions: any[] = [
    { label: 'New', value: 'new' },
    { label: 'Synchronized', value: 'synchronized' },
    { label: 'In Progress', value: 'in-progress' },
    { label: 'Approved', value: 'closed' },
  ]
  paymentStatusOptions: any[] = [
    { label: 'Paid', value: 'paid' },
    { label: 'Unpaid', value: 'unpaid' },
  ]
  typeOptions: any[] = [
    { label: 'Repayment Schedule', value: 'repayment-schedule' },
    { label: 'Product', value: 'product' },
    { label: 'CLAT Tax', value: 'clat-tax' },
  ]
  defaultTabOption: string = 'filter-products';
  tabOption: string = 'filter-products';
  tabOptions: any = [
    {
      label: 'Filter products',
      key: 'filter-products'
    },
    {
      label: 'Filter transactions',
      key: 'filter-transactions'
    },
  ];

  transferNumber: string = null
  initialPayment: string = null
  accountNumber: string = null

  filters: Record<string, Filter> = {
    fullNumber: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    accountNumber: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    issueDate: { value: null, getQueryValue: FilterFunctions.dateRange, operator: FilterOperators.like, type: 'dateRange' },
    closeDate: { value: null, getQueryValue: FilterFunctions.dateRange, operator: FilterOperators.like, type: 'dateRange' },
    balance: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    movements: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    revenue: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    expenditure: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    closingBalance: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    systemBalance: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    systemMovements: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    systemRevenue: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    systemExpenditure: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    systemClosingBalance: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    status: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    paymentStatus: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    currency: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    type: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    code: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    transferNumber: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    date: { value: null, getQueryValue: FilterFunctions.dateRange, operator: FilterOperators.like, type: 'dateRange' },
    transferDate: { value: null, getQueryValue: FilterFunctions.dateRange, operator: FilterOperators.like, type: 'dateRange' },
    investorName: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    counterpartyName: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    counterpartyAccountNumber: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    value: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    amount: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    description: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
  };

  changeTabOption(key: string) {
    this.tabOption = key
    this.clearAll()
  }

  get filteredAccountNumberOptions() {
    return this.accountNumberOptions?.filter((el: any) => el?.label?.replaceAll(' ', '')?.toLowerCase().includes(this.bankAccountNumberSearch?.toLowerCase()))
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get toggleText(): string {
    return this.toggleFilters ? 'SHOW' : 'HIDE';
  }

  get tableQueryFilters() {
    return this.$store.getters['statements/getStatementsTableQuery'].filters
  }

  handleBankAccountSearch(value: string) {
    this.bankAccountNumberSearch = value?.replaceAll(' ', '')
  }

  handleFiltersToggle(): void {
    this.toggleFilters = !this.toggleFilters;
  }

  updateCurrencies(currencies: Array<string>) {
    if (currencies.includes('PLN')) {
      currencies.push(null)
    }
    this.selectedStatementCurrencies = currencies
    this.filters.currency.value = currencies;
  }

  toggleAllCurrencies() {
    if (this.filters.currency.value?.length < this.usedCurrencies.length) {
      this.updateCurrencies(this.usedCurrencies.map((el: any) => el.value))
    } else {
      this.updateCurrencies([])
    }
  }

  updateAccountNumbers(accountNumbers: string[]) {
    this.selectedAccountNumbers = accountNumbers
    this.filters.accountNumber.value = accountNumbers
  }

  toggleAllAccountNumbers() {
    if (this.filters.accountNumber.value?.length < this.accountNumberOptions.length) {
      this.updateAccountNumbers(this.accountNumberOptions.map((el: any) => el.value))
    } else {
      this.updateAccountNumbers([])
    }
  }

  updateStatuses(statuses: Array<string>) {
    this.selectedStatusOptions = statuses
    this.filters.status.value = statuses;
  }

  toggleAllStatuses() {
    if (this.filters.status.value?.length < this.statusOptions.length) {
      this.updateStatuses(this.statusOptions.map((el: any) => el.value))
    } else {
      this.updateStatuses([])
    }
  }

  updateTypes(types: Array<string>) {
    this.selectedTypeOptions = types
    this.filters.type.value = types;
  }

  updatePaymentStatuses(types: Array<string>) {
    this.selectedPaymentStatusOptions = types
    this.filters.paymentStatus.value = types;
  }

  toggleAllTypes() {
    if (this.filters.type.value?.length < this.typeOptions.length) {
      this.updateTypes(this.typeOptions.map((el: any) => el.value))
    } else {
      this.updateTypes([])
    }
  }

  toggleAllPaymentStatuses() {
    if (this.filters.paymentStatus.value?.length < this.paymentStatusOptions.length) {
      this.updatePaymentStatuses(this.paymentStatusOptions.map((el: any) => el.value))
    } else {
      this.updatePaymentStatuses([])
    }
  }

  applyFilters(scroll: boolean = false): void {
    if (this.isLoading) return;
    if (scroll) this.$emit('scrollToSection', this.tabOption)
    this.$emit('filtersChange', this.getFilterQuery());
  }

  clearAll() {
    for (let key in this.filters) {
      if (Array.isArray(this.filters[key].value)) {
        this.filters[key].value = [];
      } else if (this.filters[key].value?.to) {
        this.filters[key].value.to = '';
        this.filters[key].value.from = '';
      } else {
        this.filters[key].value = '';
      }
    }
    this.updateAccountNumbers([])
    this.updatePaymentStatuses([])
    this.updateCurrencies([])
    this.updateStatuses([])
    this.bankAccountNumber = null
    this.transferNumber = null
    this.initialPayment = null
    this.accountNumber = null
    this.applyFilters();
  }

  loadFiltersFromStore() {
    const filtersData = FilterHelpers.parseURLFilters(this.tableQueryFilters);

    if (filtersData) {
      let formattedFilters: any = {};

      for (let [key, value] of Object.entries(filtersData)) {
        key = key.substring(1);
        const index = key.indexOf(']');
        key = key.slice(0, index) + key.slice(index + 1);

        const keyName = key.slice(0, key.indexOf('['));

        const indexValue = key.substring(
            key.indexOf('[') + 1,
            key.lastIndexOf(']')
        );

        if (formattedFilters[keyName]) {
          if (indexValue == '0') {
            formattedFilters[keyName] = [formattedFilters[keyName]]
          } else {
            formattedFilters[keyName].push((value as any)[0]);
          }
        } else {
          formattedFilters[keyName] = value;
        }

      }

      for (let [key, value] of Object.entries(formattedFilters)) {
        if (this.filters[key].type == 'dateRange') {
          this.filters[key].value = [
            (value as any)[0].replace(' 00:00:00', ''),
            (value as any)[1].replace(' 23:59:59', ''),
          ];
        } else if (this.filters[key].type == 'range') {
          this.filters[key].value = {
            from: (value as any)[0],
            to: ((value as any)[1])
          }
        } else if (this.filters[key].type == 'string' && this.filters[key].operator != 'in') {
          this.filters[key].value = (value as any)[0]
        } else {
          this.filters[key].value = (value as any);
        }
      }
    }
  }

  @Watch('tabOption') onTabOptionUpdate() {
    if (this.type === 'reconciliations') {
      if (this.tabOption === 'filter-transactions') {
        this.itemsUrl = 'bank-statement/transaction/for-products'
      } else {
        this.itemsUrl = 'bank-statement/payment/for-product'
      }

      this.applyFilters()
    }
  }

  @Watch('type') onTypeUpdate() {
    if (this.type === 'bank-statements') {
      this.itemsUrl = 'bank-statement/statement'
    } else {
      this.itemsUrl = 'bank-statement/payment/for-product'
    }
  }

  @Watch('transferNumber') onTransferNumberUpdate() {
    this.filters.transferNumber.value = (this.transferNumber) ? this.transferNumber.replaceAll(' ', '') : null
  }

  @Watch('initialPayment') onInitialPaymentUpdate() {
    this.filters.accountNumber.value = (this.initialPayment) ? this.initialPayment.replaceAll(' ', '') : null
  }

  @Watch('accountNumber') onAccountNumberUpdate() {
    if (this.tabOption === 'filter-transactions') {
      this.filters.counterpartyAccountNumber.value = (this.accountNumber) ? this.accountNumber.replaceAll(' ', '') : null
    } else {
      this.filters.accountNumber.value = (this.accountNumber) ? this.accountNumber.replaceAll(' ', '') : null
    }
  }

  @Watch('bankAccountNumber') onBankAccountNumberUpdate() {
    if (this.type === 'bank-statements') {
      this.filters.accountNumber.value = (this.bankAccountNumber) ? this.bankAccountNumber.replaceAll(' ', '') : null
    } else {
      if (this.tabOption === 'filter-transactions') {
        this.filters.counterpartyAccountNumber.value = (this.bankAccountNumber) ? this.bankAccountNumber.replaceAll(' ', '') : null
      } else {
        this.filters.accountNumber.value = (this.bankAccountNumber) ? this.bankAccountNumber.replaceAll(' ', '') : null
      }
    }
  }

  async mounted() {
    this.loadFiltersFromStore();
    try {
      const bankAccounts = await this.$store.dispatch('statements/getBankAccounts')
      this.accountNumberOptions = bankAccounts?.map((el: any) => {
        el.label = this.$options.filters.bankAccountNumber(el.label)

        return el
      })
    } catch (e) {
      e;
    }
  }
}
