var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['marketplace-filters filters-container filters table', _vm.toggleFilters ? 'filters--hidden' : '']
  }, [_vm.type === 'bank-statements' ? [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'REPORT NO.'
    },
    model: {
      value: _vm.filters.fullNumber.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.fullNumber, "value", $$v);
      },
      expression: "filters.fullNumber.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-multi-select', {
    staticClass: "account-number",
    attrs: {
      "label": 'ACCOUNT NO.',
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "multiple": true,
      "options": _vm.filteredAccountNumberOptions,
      "selectAll": true,
      "searchable": false,
      "internalSearch": false,
      "emptyText": "Type for search"
    },
    on: {
      "input": _vm.updateAccountNumbers,
      "searchChange": _vm.handleBankAccountSearch,
      "toggleAll": _vm.toggleAllAccountNumbers
    },
    model: {
      value: _vm.selectedAccountNumbers,
      callback: function callback($$v) {
        _vm.selectedAccountNumbers = $$v;
      },
      expression: "selectedAccountNumbers"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "label": 'OP. DATE',
      "range": true
    },
    model: {
      value: _vm.filters.issueDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.issueDate, "value", $$v);
      },
      expression: "filters.issueDate.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "label": 'CL. DATE',
      "range": true
    },
    model: {
      value: _vm.filters.closeDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.closeDate, "value", $$v);
      },
      expression: "filters.closeDate.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "displayFormat": 'currency',
      "label": 'OP. BALANCE CCY'
    },
    model: {
      value: _vm.filters.balance.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.balance, "value", $$v);
      },
      expression: "filters.balance.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "displayFormat": 'currency',
      "label": 'TURNOVER CCY'
    },
    model: {
      value: _vm.filters.movements.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.movements, "value", $$v);
      },
      expression: "filters.movements.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "displayFormat": 'currency',
      "label": 'INFLOWS CCY'
    },
    model: {
      value: _vm.filters.revenue.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.revenue, "value", $$v);
      },
      expression: "filters.revenue.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "displayFormat": 'currency',
      "label": 'OUTFLOWS CCY'
    },
    model: {
      value: _vm.filters.expenditure.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.expenditure, "value", $$v);
      },
      expression: "filters.expenditure.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "displayFormat": 'currency',
      "label": 'CL. BALANCE CCY'
    },
    model: {
      value: _vm.filters.closingBalance.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.closingBalance, "value", $$v);
      },
      expression: "filters.closingBalance.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'CURRENCY',
      "multiple": true,
      "options": _vm.usedCurrencies,
      "preserve-search": false,
      "searchable": true,
      "select-all": true
    },
    on: {
      "input": _vm.updateCurrencies,
      "toggleAll": _vm.toggleAllCurrencies
    },
    model: {
      value: _vm.selectedStatementCurrencies,
      callback: function callback($$v) {
        _vm.selectedStatementCurrencies = $$v;
      },
      expression: "selectedStatementCurrencies"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "displayFormat": 'currency',
      "label": 'OP. BALANCE PLN'
    },
    model: {
      value: _vm.filters.systemBalance.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.systemBalance, "value", $$v);
      },
      expression: "filters.systemBalance.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "displayFormat": 'currency',
      "label": 'TURNOVER PLN'
    },
    model: {
      value: _vm.filters.systemMovements.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.systemMovements, "value", $$v);
      },
      expression: "filters.systemMovements.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "displayFormat": 'currency',
      "label": 'INFLOWS PLN'
    },
    model: {
      value: _vm.filters.systemRevenue.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.systemRevenue, "value", $$v);
      },
      expression: "filters.systemRevenue.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "displayFormat": 'currency',
      "label": 'OUTFLOWS PLN'
    },
    model: {
      value: _vm.filters.systemExpenditure.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.systemExpenditure, "value", $$v);
      },
      expression: "filters.systemExpenditure.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "displayFormat": 'currency',
      "label": 'CL. BALANCE PLN'
    },
    model: {
      value: _vm.filters.systemClosingBalance.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.systemClosingBalance, "value", $$v);
      },
      expression: "filters.systemClosingBalance.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('div', {
    staticClass: "document-status"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'STATUS',
      "multiple": true,
      "options": _vm.statusOptions,
      "preserve-search": false,
      "searchable": true,
      "select-all": true
    },
    on: {
      "input": _vm.updateStatuses,
      "toggleAll": _vm.toggleAllStatuses
    },
    model: {
      value: _vm.selectedStatusOptions,
      callback: function callback($$v) {
        _vm.selectedStatusOptions = $$v;
      },
      expression: "selectedStatusOptions"
    }
  })], 1)]), _c('div', {
    staticClass: "col-2"
  }, [_c('div', {
    staticClass: "clear-all-button",
    on: {
      "click": function click($event) {
        return _vm.clearAll();
      }
    }
  }, [_vm._v("Clear all")])]), _c('div', {
    staticClass: "col-2"
  }, [_c('div', {
    staticClass: "show-results-button"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled gn-secondary",
    attrs: {
      "loading": _vm.isLoading,
      "selectable": false
    },
    on: {
      "click": function click($event) {
        return _vm.applyFilters();
      }
    }
  }, [_vm._v(" Show results (" + _vm._s(_vm.itemsCount) + ") ")])], 1)])])] : _vm._e(), _vm.type === 'reconciliations' ? [_c('div', {
    staticClass: "row"
  }, [_c('sygni-tabs', {
    attrs: {
      "options": _vm.tabOptions,
      "defaultOption": _vm.defaultTabOption
    },
    on: {
      "click": _vm.changeTabOption
    },
    scopedSlots: _vm._u([{
      key: "filter-products",
      fn: function fn() {
        return [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-2"
        }, [_c('sygni-input', {
          attrs: {
            "label": 'CODE'
          },
          model: {
            value: _vm.filters.code.value,
            callback: function callback($$v) {
              _vm.$set(_vm.filters.code, "value", $$v);
            },
            expression: "filters.code.value"
          }
        })], 1), _c('div', {
          staticClass: "col-2"
        }, [_c('sygni-input', {
          attrs: {
            "label": 'TRANSFER NO.'
          },
          model: {
            value: _vm.transferNumber,
            callback: function callback($$v) {
              _vm.transferNumber = $$v;
            },
            expression: "transferNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-2"
        }, [_c('sygni-date-picker', {
          staticClass: "border-collapse",
          attrs: {
            "label": 'AGREEMENT DATE',
            "range": true
          },
          model: {
            value: _vm.filters.date.value,
            callback: function callback($$v) {
              _vm.$set(_vm.filters.date, "value", $$v);
            },
            expression: "filters.date.value"
          }
        })], 1), _c('div', {
          staticClass: "col-2"
        }, [_c('sygni-input', {
          attrs: {
            "label": 'INVESTOR NAME'
          },
          model: {
            value: _vm.filters.investorName.value,
            callback: function callback($$v) {
              _vm.$set(_vm.filters.investorName, "value", $$v);
            },
            expression: "filters.investorName.value"
          }
        })], 1), _c('div', {
          staticClass: "col-2"
        }, [_c('sygni-input', {
          attrs: {
            "label": 'INITIAL PAYMENT',
            "displayFormat": "bankAccount"
          },
          model: {
            value: _vm.accountNumber,
            callback: function callback($$v) {
              _vm.accountNumber = $$v;
            },
            expression: "accountNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-2"
        }, [_c('sygni-range-input', {
          attrs: {
            "displayFormat": 'currency',
            "label": 'VALUE'
          },
          model: {
            value: _vm.filters.value.value,
            callback: function callback($$v) {
              _vm.$set(_vm.filters.value, "value", $$v);
            },
            expression: "filters.value.value"
          }
        })], 1), _c('div', {
          staticClass: "col-2"
        }, [_c('sygni-multi-select', {
          attrs: {
            "clear-on-select": false,
            "close-on-select": false,
            "display-values": true,
            "label": 'CURRENCY',
            "multiple": true,
            "options": _vm.usedCurrencies,
            "preserve-search": false,
            "searchable": true,
            "select-all": true
          },
          on: {
            "input": _vm.updateCurrencies,
            "toggleAll": _vm.toggleAllCurrencies
          },
          model: {
            value: _vm.selectedStatementCurrencies,
            callback: function callback($$v) {
              _vm.selectedStatementCurrencies = $$v;
            },
            expression: "selectedStatementCurrencies"
          }
        })], 1), _c('div', {
          staticClass: "col-2"
        }, [_c('div', {
          staticClass: "document-status"
        }, [_c('sygni-multi-select', {
          attrs: {
            "clear-on-select": false,
            "close-on-select": false,
            "display-values": true,
            "label": 'TYPE',
            "multiple": true,
            "options": _vm.typeOptions,
            "preserve-search": false,
            "searchable": true,
            "select-all": true
          },
          on: {
            "input": _vm.updateTypes,
            "toggleAll": _vm.toggleAllTypes
          },
          model: {
            value: _vm.selectedTypeOptions,
            callback: function callback($$v) {
              _vm.selectedTypeOptions = $$v;
            },
            expression: "selectedTypeOptions"
          }
        })], 1)]), _c('div', {
          staticClass: "col-2"
        }, [_c('div', {
          staticClass: "document-status"
        }, [_c('sygni-multi-select', {
          attrs: {
            "clear-on-select": false,
            "close-on-select": false,
            "display-values": true,
            "label": 'PAYMENT STATUS',
            "multiple": true,
            "options": _vm.paymentStatusOptions,
            "preserve-search": false,
            "searchable": true,
            "select-all": true
          },
          on: {
            "input": _vm.updatePaymentStatuses,
            "toggleAll": _vm.toggleAllPaymentStatuses
          },
          model: {
            value: _vm.selectedPaymentStatusOptions,
            callback: function callback($$v) {
              _vm.selectedPaymentStatusOptions = $$v;
            },
            expression: "selectedPaymentStatusOptions"
          }
        })], 1)]), _c('div', {
          staticClass: "offset-2 col-2"
        }, [_c('div', {
          staticClass: "clear-all-button",
          on: {
            "click": function click($event) {
              return _vm.clearAll();
            }
          }
        }, [_vm._v("Clear all")])]), _c('div', {
          staticClass: "col-2"
        }, [_c('div', {
          staticClass: "show-results-button"
        }, [_c('sygni-rounded-button', {
          staticClass: "filled gn-secondary",
          attrs: {
            "loading": _vm.isLoading,
            "selectable": false
          },
          on: {
            "click": function click($event) {
              return _vm.applyFilters(true);
            }
          }
        }, [_vm._v(" Show results (" + _vm._s(_vm.itemsCount) + ") ")])], 1)])])];
      },
      proxy: true
    }, {
      key: "filter-transactions",
      fn: function fn() {
        return [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-2"
        }, [_c('sygni-input', {
          attrs: {
            "label": 'TRANSFER NO.'
          },
          model: {
            value: _vm.transferNumber,
            callback: function callback($$v) {
              _vm.transferNumber = $$v;
            },
            expression: "transferNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-2"
        }, [_c('sygni-input', {
          attrs: {
            "label": 'CODE'
          },
          model: {
            value: _vm.filters.code.value,
            callback: function callback($$v) {
              _vm.$set(_vm.filters.code, "value", $$v);
            },
            expression: "filters.code.value"
          }
        })], 1), _c('div', {
          staticClass: "col-2"
        }, [_c('sygni-date-picker', {
          staticClass: "border-collapse",
          attrs: {
            "label": 'TRANSFER DATE',
            "range": true
          },
          model: {
            value: _vm.filters.transferDate.value,
            callback: function callback($$v) {
              _vm.$set(_vm.filters.transferDate, "value", $$v);
            },
            expression: "filters.transferDate.value"
          }
        })], 1), _c('div', {
          staticClass: "col-2"
        }, [_c('sygni-input', {
          attrs: {
            "label": 'CP. NAME'
          },
          model: {
            value: _vm.filters.counterpartyName.value,
            callback: function callback($$v) {
              _vm.$set(_vm.filters.counterpartyName, "value", $$v);
            },
            expression: "filters.counterpartyName.value"
          }
        })], 1), _c('div', {
          staticClass: "col-2"
        }, [_c('sygni-input', {
          attrs: {
            "label": 'CP. ACCOUNT NO.',
            "displayFormat": "bankAccount"
          },
          model: {
            value: _vm.accountNumber,
            callback: function callback($$v) {
              _vm.accountNumber = $$v;
            },
            expression: "accountNumber"
          }
        })], 1), _c('div', {
          staticClass: "col-2"
        }, [_c('sygni-range-input', {
          attrs: {
            "displayFormat": 'currency',
            "label": 'AMOUNT'
          },
          model: {
            value: _vm.filters.amount.value,
            callback: function callback($$v) {
              _vm.$set(_vm.filters.amount, "value", $$v);
            },
            expression: "filters.amount.value"
          }
        })], 1), _c('div', {
          staticClass: "col-2"
        }, [_c('sygni-multi-select', {
          attrs: {
            "clear-on-select": false,
            "close-on-select": false,
            "display-values": true,
            "label": 'CURRENCY',
            "multiple": true,
            "options": _vm.usedCurrencies,
            "preserve-search": false,
            "searchable": true,
            "select-all": true
          },
          on: {
            "input": _vm.updateCurrencies,
            "toggleAll": _vm.toggleAllCurrencies
          },
          model: {
            value: _vm.selectedStatementCurrencies,
            callback: function callback($$v) {
              _vm.selectedStatementCurrencies = $$v;
            },
            expression: "selectedStatementCurrencies"
          }
        })], 1), _c('div', {
          staticClass: "col-4"
        }, [_c('sygni-input', {
          attrs: {
            "label": 'DESCRIPTION'
          },
          model: {
            value: _vm.filters.description.value,
            callback: function callback($$v) {
              _vm.$set(_vm.filters.description, "value", $$v);
            },
            expression: "filters.description.value"
          }
        })], 1), _c('div', {
          staticClass: "offset-2 col-2"
        }, [_c('div', {
          staticClass: "clear-all-button",
          on: {
            "click": function click($event) {
              return _vm.clearAll();
            }
          }
        }, [_vm._v("Clear all")])]), _c('div', {
          staticClass: "col-2"
        }, [_c('div', {
          staticClass: "show-results-button"
        }, [_c('sygni-rounded-button', {
          staticClass: "filled gn-secondary",
          attrs: {
            "loading": _vm.isLoading,
            "selectable": false
          },
          on: {
            "click": function click($event) {
              return _vm.applyFilters(true);
            }
          }
        }, [_vm._v(" Show results (" + _vm._s(_vm.itemsCount) + ") ")])], 1)])])];
      },
      proxy: true
    }], null, false, 1404214306)
  })], 1)] : _vm._e(), _c('div', {
    class: ['filters__toggle', !_vm.toggleFilters ? 'filters__toggle--active' : ''],
    on: {
      "click": _vm.handleFiltersToggle
    }
  }, [_c('p', {
    staticClass: "filters__toggle-option"
  }, [_vm._v(_vm._s(_vm.toggleText))]), _c('span', {
    staticClass: "filters__toggle-arrow"
  }, [_vm._v(">>>")])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }