var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['marketplace-filters filters-container filters table', _vm.toggleFilters ? 'filters--hidden' : '']
  }, [_vm.type === 'reconciliations' ? [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('sygni-container-title', {
    staticClass: "mb-4"
  }, [_vm._v("Search products")])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'CODE'
    },
    model: {
      value: _vm.productFilters.code.value,
      callback: function callback($$v) {
        _vm.$set(_vm.productFilters.code, "value", $$v);
      },
      expression: "productFilters.code.value"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'TRANSFER NO.'
    },
    model: {
      value: _vm.productTransferNumber,
      callback: function callback($$v) {
        _vm.productTransferNumber = $$v;
      },
      expression: "productTransferNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "label": 'AGREEMENT DATE',
      "range": true
    },
    model: {
      value: _vm.productFilters.date.value,
      callback: function callback($$v) {
        _vm.$set(_vm.productFilters.date, "value", $$v);
      },
      expression: "productFilters.date.value"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'INVESTOR NAME'
    },
    model: {
      value: _vm.productFilters.investorName.value,
      callback: function callback($$v) {
        _vm.$set(_vm.productFilters.investorName, "value", $$v);
      },
      expression: "productFilters.investorName.value"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'INITIAL PAYMENT',
      "displayFormat": "bankAccount"
    },
    model: {
      value: _vm.productAccountNumber,
      callback: function callback($$v) {
        _vm.productAccountNumber = $$v;
      },
      expression: "productAccountNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-range-input', {
    attrs: {
      "displayFormat": 'currency',
      "label": 'VALUE'
    },
    model: {
      value: _vm.productFilters.value.value,
      callback: function callback($$v) {
        _vm.$set(_vm.productFilters.value, "value", $$v);
      },
      expression: "productFilters.value.value"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'CURRENCY',
      "multiple": true,
      "options": _vm.usedCurrencies,
      "preserve-search": false,
      "searchable": true,
      "select-all": true
    },
    on: {
      "input": _vm.updateProductCurrencies,
      "toggleAll": _vm.toggleAllProductCurrencies
    },
    model: {
      value: _vm.selectedProductCurrencies,
      callback: function callback($$v) {
        _vm.selectedProductCurrencies = $$v;
      },
      expression: "selectedProductCurrencies"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "document-status"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'PAYMENT STATUS',
      "multiple": true,
      "options": _vm.paymentStatusOptions,
      "preserve-search": false,
      "searchable": true,
      "select-all": true
    },
    on: {
      "input": _vm.updatePaymentStatuses,
      "toggleAll": _vm.toggleAllPaymentStatuses
    },
    model: {
      value: _vm.selectedPaymentStatusOptions,
      callback: function callback($$v) {
        _vm.selectedPaymentStatusOptions = $$v;
      },
      expression: "selectedPaymentStatusOptions"
    }
  })], 1)])])]), _c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('sygni-container-title', {
    staticClass: "mb-4"
  }, [_vm._v("Search transactions")])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'TRANSFER NO.'
    },
    model: {
      value: _vm.transactionTransferNumber,
      callback: function callback($$v) {
        _vm.transactionTransferNumber = $$v;
      },
      expression: "transactionTransferNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'CODE'
    },
    model: {
      value: _vm.transactionFilters.code.value,
      callback: function callback($$v) {
        _vm.$set(_vm.transactionFilters.code, "value", $$v);
      },
      expression: "transactionFilters.code.value"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "label": 'TRANSFER DATE',
      "range": true
    },
    model: {
      value: _vm.transactionFilters.transferDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.transactionFilters.transferDate, "value", $$v);
      },
      expression: "transactionFilters.transferDate.value"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'CP. NAME'
    },
    model: {
      value: _vm.transactionFilters.counterpartyName.value,
      callback: function callback($$v) {
        _vm.$set(_vm.transactionFilters.counterpartyName, "value", $$v);
      },
      expression: "transactionFilters.counterpartyName.value"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'CP. ACCOUNT NO.',
      "displayFormat": "bankAccount"
    },
    model: {
      value: _vm.transactionAccountNumber,
      callback: function callback($$v) {
        _vm.transactionAccountNumber = $$v;
      },
      expression: "transactionAccountNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-range-input', {
    attrs: {
      "displayFormat": 'currency',
      "label": 'AMOUNT'
    },
    model: {
      value: _vm.transactionFilters.amount.value,
      callback: function callback($$v) {
        _vm.$set(_vm.transactionFilters.amount, "value", $$v);
      },
      expression: "transactionFilters.amount.value"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'CURRENCY',
      "multiple": true,
      "options": _vm.usedCurrencies,
      "preserve-search": false,
      "searchable": true,
      "select-all": true
    },
    on: {
      "input": _vm.updateTransactionCurrencies,
      "toggleAll": _vm.toggleAllTransactionCurrencies
    },
    model: {
      value: _vm.selectedTransactionCurrencies,
      callback: function callback($$v) {
        _vm.selectedTransactionCurrencies = $$v;
      },
      expression: "selectedTransactionCurrencies"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'DESCRIPTION'
    },
    model: {
      value: _vm.transactionFilters.description.value,
      callback: function callback($$v) {
        _vm.$set(_vm.transactionFilters.description, "value", $$v);
      },
      expression: "transactionFilters.description.value"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'STATUS',
      "multiple": true,
      "options": _vm.statusOptions,
      "preserve-search": false,
      "searchable": true,
      "select-all": true
    },
    on: {
      "input": _vm.updateStatuses,
      "toggleAll": _vm.toggleAllStatuses
    },
    model: {
      value: _vm.selectedStatusOptions,
      callback: function callback($$v) {
        _vm.selectedStatusOptions = $$v;
      },
      expression: "selectedStatusOptions"
    }
  })], 1), _c('div', {
    staticClass: "offset-4 col-4"
  }, [_c('div', {
    staticClass: "clear-all-button",
    on: {
      "click": function click($event) {
        return _vm.clearAll();
      }
    }
  }, [_vm._v("Clear all")])]), _c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "show-results-button"
  }, [_c('sygni-rounded-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.showResultsBtnTooltipMessage,
      expression: "showResultsBtnTooltipMessage",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "filled gn-secondary",
    attrs: {
      "loading": _vm.isLoading,
      "selectable": false
    },
    on: {
      "click": function click($event) {
        return _vm.applyFilters(true);
      }
    }
  }, [_vm._v(" Show results ")])], 1)])])])])] : _vm._e(), _c('div', {
    class: ['filters__toggle', !_vm.toggleFilters ? 'filters__toggle--active' : ''],
    on: {
      "click": _vm.handleFiltersToggle
    }
  }, [_c('p', {
    staticClass: "filters__toggle-option"
  }, [_vm._v(_vm._s(_vm.toggleText))]), _c('span', {
    staticClass: "filters__toggle-arrow"
  }, [_vm._v(">>>")])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }