var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "statement-module"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('sygni-tabs', {
    ref: "tabs",
    attrs: {
      "options": _vm.tabOptions,
      "defaultOption": _vm.defaultTabOption
    },
    on: {
      "click": _vm.selectTab
    },
    scopedSlots: _vm._u([{
      key: "beforeContent",
      fn: function fn() {
        return [_vm.selectedTab === 'bank-statements' ? _c('statement-filters', {
          ref: "statementFilters",
          attrs: {
            "type": _vm.selectedTab
          },
          on: {
            "filtersChange": _vm.applyFiltersEvent,
            "scrollToSection": _vm.scrollToSection
          }
        }) : _vm._e(), _vm.selectedTab === 'reconciliations' ? _c('reconciliation-filters', {
          ref: "reconciliationFilters",
          attrs: {
            "type": _vm.selectedTab
          },
          on: {
            "filtersChange": _vm.applyReconciliationFiltersEvent,
            "scrollToSection": _vm.scrollToSection
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_vm.selectedTab === 'bank-statements' ? [_c('sygni-link-button', {
          staticClass: "filled gn-secondary",
          attrs: {
            "type": "simple"
          },
          on: {
            "click": _vm.toggleAdditionalColumns
          }
        }, [_vm._v(_vm._s(_vm.toggleColumnsText))]), _c('sygni-rounded-button', {
          staticClass: "filled gn-primary",
          on: {
            "click": _vm.synchronizeReports
          }
        }, [_vm._v("Synchronise reports")])] : _vm._e()];
      },
      proxy: true
    }, {
      key: "bank-statements",
      fn: function fn() {
        return [_c('statement-table', {
          ref: "statementTable",
          attrs: {
            "hideAdditionalColumns": _vm.hideAdditionalColumns,
            "show-page-number-options": true,
            "tableData": _vm.statementsTable,
            "tablePerPageMutation": "statements/setStatementsTablePerPage",
            "setTableBusyMutation": "statements/setStatementsTableBusy",
            "busy": _vm.statementsAreBusy,
            "perPage": _vm.statementsPerPage,
            "getItemsAction": "statements/getStatements"
          }
        })];
      },
      proxy: true
    }, {
      key: "reconciliations",
      fn: function fn() {
        return [_c('statement-products-table', {
          ref: "statementProductsTable",
          staticClass: "mb-5",
          attrs: {
            "infinity-scroll": true,
            "tableData": _vm.statementProductsTable,
            "tablePerPageMutation": "statements/setStatementProductsTablePerPage",
            "setTableBusyMutation": "statements/setStatementProductsTableBusy",
            "busy": _vm.statementProductsAreBusy,
            "perPage": 9999999,
            "getItemsAction": "statements/getStatementProducts"
          },
          on: {
            "productSelected": _vm.handleProductSelection
          }
        }), _c('statement-transactions-table', {
          ref: "statementTransactionsTable",
          attrs: {
            "infinity-scroll": true,
            "tableData": _vm.statementTransactionsTable,
            "tablePerPageMutation": "statements/setStatementTransactionsTablePerPage",
            "setTableBusyMutation": "statements/setStatementTransactionsTableBusy",
            "busy": _vm.statementTransactionsAreBusy,
            "perPage": 9999999,
            "getItemsAction": "statements/getStatementTransactions",
            "productObject": _vm.productObject
          },
          on: {
            "openCombineModal": function openCombineModal($event) {
              _vm.showCombineModal = true;
            },
            "matched": _vm.refreshTables
          }
        })];
      },
      proxy: true
    }])
  }), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.syncInfo,
      expression: "syncInfo"
    }],
    attrs: {
      "header": "Success",
      "size": "large",
      "description": "Reports have been synchronized successfully. Here's a list of changes:",
      "confirmText": "OK",
      "cancelText": ""
    },
    on: {
      "close": _vm.closeSyncInfoModal,
      "confirm": _vm.closeSyncInfoModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('b-table', {
          ref: "uploadFilesTable",
          staticClass: "sygni-b-inner-table",
          attrs: {
            "innertable": "true",
            "fields": [{
              key: 'fullNumber',
              sortable: false,
              class: ['full-number']
            }, {
              key: 'status',
              sortable: false,
              class: ['status'],
              label: 'Action'
            }],
            "items": _vm.syncInfoItems
          },
          scopedSlots: _vm._u([{
            key: "cell(fullNumber)",
            fn: function fn(rowData) {
              var _rowData$item2, _rowData$item3;

              return [_c('div', {
                staticClass: "full-report-link",
                on: {
                  "click": function click($event) {
                    var _rowData$item;

                    return _vm.openReport(rowData === null || rowData === void 0 ? void 0 : (_rowData$item = rowData.item) === null || _rowData$item === void 0 ? void 0 : _rowData$item.id);
                  }
                }
              }, [_vm._v(_vm._s(rowData !== null && rowData !== void 0 && (_rowData$item2 = rowData.item) !== null && _rowData$item2 !== void 0 && _rowData$item2.fullNumber ? rowData === null || rowData === void 0 ? void 0 : (_rowData$item3 = rowData.item) === null || _rowData$item3 === void 0 ? void 0 : _rowData$item3.fullNumber : ''))])];
            }
          }, {
            key: "cell(status)",
            fn: function fn(rowData) {
              return [_c('div', {
                staticClass: "table__status d-flex justify-content-center align-items-center"
              }, [_c('sygni-rounded-button', {
                class: ['outline round', _vm.getReportStatusClass(rowData.item.status)],
                attrs: {
                  "hoverable": false
                }
              }, [_vm._v(_vm._s(_vm.getReportStatusText(rowData.item.status)))])], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }