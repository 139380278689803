























































































































import Component, { mixins } from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniModal from '@/components/layout/SygniModal.vue';
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniMultiSelect from "@/components/inputs/SygniMultiSelect.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniRangeInput from "@/components/inputs/SygniRangeInput.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniTabs from '@/components/layout/SygniTabs.vue';
import SygniButtonsGroup from "@/components/layout/SygniButtonsGroup.vue";
import { Filter, FilterFunctions, FilterHelpers, FilterMixin, FilterOperators } from "@/shared/mixins/FilterMixin";
import SygniCheckableButton from "@/components/buttons/SygniCheckableButton.vue";
import { Prop, Watch } from 'vue-property-decorator';
import { BACKEND_API_URL, usedCurrencies } from '@/shared/consts';
import { create, all } from 'mathjs'
import axios from 'axios';

const math = create(all);

@Component({
  components: {
    SygniModal,
    SygniCheckableButton,
    SygniRangeInput,
    SygniButtonsGroup,
    SygniDatePicker,
    SygniContainerTitle,
    SygniRoundedButton,
    SygniSquareButton,
    SygniSelect,
    SygniInput,
    SygniMultiSelect,
    SygniTabs,
  },
})

export default class ReconciliationFilters extends mixins(FilterMixin) {
  @Prop({ default: null }) type: string;
  itemsUrl: string = 'bank-statement/statement';
  toggleFilters: boolean = false;
  
  selectedProductCurrencies: any[] = []
  selectedTransactionCurrencies: any[] = []
  selectedStatusOptions: any[] = []
  selectedTypeOptions: any[] = []
  selectedPaymentStatusOptions: any[] = []
  bankAccountNumber: string = null
  usedCurrencies: any = usedCurrencies;
  statusOptions: any[] = [
    { label: 'Unmatched', value: 'unmatched' },
    { label: 'Matched', value: 'matched' },
    { label: 'Booked', value: 'booked' },
  ]
  paymentStatusOptions: any[] = [
    { label: 'Paid', value: 'paid' },
    { label: 'Unpaid', value: 'unpaid' },
    { label: 'Partially paid', value: 'partially-paid' },
  ]
  typeOptions: any[] = [
    { label: 'Repayment Schedule', value: 'repayment-schedule' },
    { label: 'Product', value: 'product' },
    { label: 'CLAT Tax', value: 'clat-tax' },
  ]
  defaultTabOption: string = 'filter-products';
  tabOption: string = 'filter-products';
  tabOptions: any = [
    {
      label: 'Filter products',
      key: 'filter-products'
    },
    {
      label: 'Filter transactions',
      key: 'filter-transactions'
    },
  ];

  productTransferNumber: string = null
  transactionTransferNumber: string = null
  initialPayment: string = null
  productAccountNumber: string = null
  transactionAccountNumber: string = null

  filters: Record<string, Filter> = {
    fullNumber: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    accountNumber: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    issueDate: { value: null, getQueryValue: FilterFunctions.dateRange, operator: FilterOperators.like, type: 'dateRange' },
    closeDate: { value: null, getQueryValue: FilterFunctions.dateRange, operator: FilterOperators.like, type: 'dateRange' },
    balance: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    movements: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    revenue: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    expenditure: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    closingBalance: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    systemBalance: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    systemMovements: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    systemRevenue: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    systemExpenditure: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    systemClosingBalance: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    status: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    paymentStatus: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    currency: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    type: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    code: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    transferNumber: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    date: { value: null, getQueryValue: FilterFunctions.dateRange, operator: FilterOperators.like, type: 'dateRange' },
    transferDate: { value: null, getQueryValue: FilterFunctions.dateRange, operator: FilterOperators.like, type: 'dateRange' },
    investorName: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    counterpartyName: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    counterpartyAccountNumber: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    value: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    amount: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    description: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
  };

  productFilters: Record<string, Filter> = {
    code: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    transferNumber: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.contain, type: 'string' },
    date: { value: null, getQueryValue: FilterFunctions.dateRange, operator: FilterOperators.like, type: 'dateRange' },
    investorName: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    accountNumber: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    value: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    currency: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    type: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    paymentStatus: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
  }

  transactionFilters: Record<string, Filter> = {
    code: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    transferNumber: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    transferDate: { value: null, getQueryValue: FilterFunctions.dateRange, operator: FilterOperators.like, type: 'dateRange' },
    counterpartyName: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    counterpartyAccountNumber: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    amount: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    currency: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    description: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    status: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
  }

  queries: any = {
    products: { endpoint: 'bank-statement/payment/for-product', filtersQuery: '', resultCount: 0 },
    transactions: { endpoint: 'bank-statement/transaction/for-products', filtersQuery: '', resultCount: 0 },
  }

  get showResultsBtnTooltipMessage() {
    return `${this.queries?.products?.resultCount || 0} product${this.queries?.products?.resultCount == 1 ? '' : 's'}, ${this.queries?.transactions?.resultCount || 0} transaction${this.queries?.transactions?.resultCount == 1 ? '' : 's'}`
  }

  getProductFilterQuery(): string {
    this.filterQuery = 'filters[type][0][value]=product&filters[type][0][operator]=in';
    for (const key in this.productFilters) {
      if (this.productFilters[key].value && this.productFilters[key].value !== '') {
        this.filterQuery += this.createSingleFilterQuery(this.productFilters[key], key);
      }
    }
    return this.filterQuery;
  }

  getTransactionFilterQuery(): string {
    this.filterQuery = '';
    for (const key in this.transactionFilters) {
      if (this.transactionFilters[key].value && this.transactionFilters[key].value !== '') {
        this.filterQuery += this.createSingleFilterQuery(this.transactionFilters[key], key);
      }
    }
    return this.filterQuery;
  }

  countItems() {
    this.isLoading = true;
    // this.filterQuery = this.getFilterQuery();
    this.queries.products.filtersQuery = this.getProductFilterQuery()
    this.queries.transactions.filtersQuery = this.getTransactionFilterQuery()

    clearTimeout(this.getItemsTimeout);
    this.getItemsTimeout = setTimeout(() => {
      this.getItemsCount().then(() => {
        this.isLoading = false;
      });
    }, 800);
  }

  async getItemsCount() {
    const promises: any[] = []

    Object.keys(this.queries).forEach((query: any) => {
      promises.push(axios.get(`${BACKEND_API_URL}/${this.queries[query].endpoint}?${this.queries[query].filtersQuery}&limit=0`, {
        headers: { 'x-total-count-only': true }
      }))
    })

    try {
      const response = await Promise.all(promises)

      this.queries.products.resultCount = response[0]?.headers['x-total-count'] || '0'
      this.queries.transactions.resultCount = response[1]?.headers['x-total-count'] || '0'

      this.itemsCount = math.add(this.queries.products.resultCount, this.queries.transactions.resultCount)
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    
  }

  @Watch('productFilters', { deep: true }) async onProductFiltersValueChange() {
    this.countItems()
  }

  @Watch('transactionFilters', { deep: true }) async onTransactionFiltersValueChange() {
    this.countItems()
  }

  changeTabOption(key: string) {
    this.tabOption = key
    this.clearAll()
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get toggleText(): string {
    return this.toggleFilters ? 'SHOW' : 'HIDE';
  }

  get tableQueryFilters() {
    return this.$store.getters['statements/getStatementsTableQuery'].filters
  }

  handleFiltersToggle(): void {
    this.toggleFilters = !this.toggleFilters;
  }

  updateProductCurrencies(currencies: Array<string>) {
    if (currencies.includes('PLN')) {
      currencies.push(null)
    }
    this.selectedProductCurrencies = currencies
    this.productFilters.currency.value = currencies;
  }

  updateTransactionCurrencies(currencies: Array<string>) {
    if (currencies.includes('PLN')) {
      currencies.push(null)
    }
    this.selectedTransactionCurrencies = currencies
    this.transactionFilters.currency.value = currencies;
  }

  toggleAllProductCurrencies() {
    if (this.productFilters.currency.value?.length < this.usedCurrencies.length) {
      this.updateProductCurrencies(this.usedCurrencies.map((el: any) => el.value))
    } else {
      this.updateProductCurrencies([])
    }
  }

  toggleAllTransactionCurrencies() {
    if (this.transactionFilters.currency.value?.length < this.usedCurrencies.length) {
      this.updateTransactionCurrencies(this.usedCurrencies.map((el: any) => el.value))
    } else {
      this.updateTransactionCurrencies([])
    }
  }

  updateStatuses(statuses: Array<string>) {
    this.selectedStatusOptions = statuses
    this.transactionFilters.status.value = statuses;
  }

  toggleAllStatuses() {
    if (this.transactionFilters.status.value?.length < this.statusOptions.length) {
      this.updateStatuses(this.statusOptions.map((el: any) => el.value))
    } else {
      this.updateStatuses([])
    }
  }

  updateTypes(types: Array<string>) {
    this.selectedTypeOptions = types
    this.productFilters.type.value = types;
  }

  updatePaymentStatuses(types: Array<string>) {
    this.selectedPaymentStatusOptions = types
    this.productFilters.paymentStatus.value = types;
  }

  toggleAllTypes() {
    if (this.productFilters.type.value?.length < this.typeOptions.length) {
      this.updateTypes(this.typeOptions.map((el: any) => el.value))
    } else {
      this.updateTypes([])
    }
  }

  toggleAllPaymentStatuses() {
    if (this.productFilters.paymentStatus.value?.length < this.paymentStatusOptions.length) {
      this.updatePaymentStatuses(this.paymentStatusOptions.map((el: any) => el.value))
    } else {
      this.updatePaymentStatuses([])
    }
  }

  applyFilters(scroll: boolean = false): void {
    if (this.isLoading) return;
    if (scroll) this.$emit('scrollToSection', this.tabOption)

    this.$store.commit('statements/setStatementTransactionsTableFiltersQuery', this.queries?.transactions?.filtersQuery)
    this.$store.commit('statements/setStatementProductsTableFiltersQuery', this.queries?.products?.filtersQuery)

    this.$emit('filtersChange', [this.queries?.products?.filtersQuery, this.queries?.transactions?.filtersQuery]);
  }

  clearAll() {
    this.queries.transactions.filtersQuery = ''
    this.queries.products.filtersQuery = ''

    for (let key in this.transactionFilters) {
      if (Array.isArray(this.transactionFilters[key].value)) {
        this.transactionFilters[key].value = [];
      } else if (this.transactionFilters[key].value?.to) {
        this.transactionFilters[key].value.to = '';
        this.transactionFilters[key].value.from = '';
      } else {
        this.transactionFilters[key].value = '';
      }
    }
    for (let key in this.productFilters) {
      if (Array.isArray(this.productFilters[key].value)) {
        this.productFilters[key].value = [];
      } else if (this.productFilters[key].value?.to) {
        this.productFilters[key].value.to = '';
        this.productFilters[key].value.from = '';
      } else {
        this.productFilters[key].value = '';
      }
    }
    this.updatePaymentStatuses([])
    this.updateProductCurrencies([])
    this.updateTransactionCurrencies([])
    this.updateStatuses([])
    this.bankAccountNumber = null
    this.productTransferNumber = null
    this.transactionTransferNumber = null
    this.initialPayment = null
    this.productAccountNumber = null
    this.transactionAccountNumber = null

    this.applyFilters();
  }

  loadFiltersFromStore() {
    const filtersData = FilterHelpers.parseURLFilters(this.tableQueryFilters);

    if (filtersData) {
      let formattedFilters: any = {};

      for (let [key, value] of Object.entries(filtersData)) {
        key = key.substring(1);
        const index = key.indexOf(']');
        key = key.slice(0, index) + key.slice(index + 1);

        const keyName = key.slice(0, key.indexOf('['));

        const indexValue = key.substring(
            key.indexOf('[') + 1,
            key.lastIndexOf(']')
        );

        if (formattedFilters[keyName]) {
          if (indexValue == '0') {
            formattedFilters[keyName] = [formattedFilters[keyName]]
          } else {
            formattedFilters[keyName].push((value as any)[0]);
          }
        } else {
          formattedFilters[keyName] = value;
        }

      }

      for (let [key, value] of Object.entries(formattedFilters)) {
        if (this.filters[key].type == 'dateRange') {
          this.filters[key].value = [
            (value as any)[0].replace(' 00:00:00', ''),
            (value as any)[1].replace(' 23:59:59', ''),
          ];
        } else if (this.filters[key].type == 'range') {
          this.filters[key].value = {
            from: (value as any)[0],
            to: ((value as any)[1])
          }
        } else if (this.filters[key].type == 'string' && this.filters[key].operator != 'in') {
          this.filters[key].value = (value as any)[0]
        } else {
          this.filters[key].value = (value as any);
        }
      }
    }
  }

  @Watch('tabOption') onTabOptionUpdate() {
    if (this.type === 'reconciliations') {
      if (this.tabOption === 'filter-transactions') {
        this.itemsUrl = 'bank-statement/transaction/for-products'
      } else {
        this.itemsUrl = 'bank-statement/payment/for-product'
      }

      this.applyFilters()
    }
  }

  @Watch('type') onTypeUpdate() {
    if (this.type === 'bank-statements') {
      this.itemsUrl = 'bank-statement/statement'
    } else {
      this.itemsUrl = 'bank-statement/payment/for-product'
    }
  }

  @Watch('productTransferNumber') onProductTransferNumberUpdate() {
    this.productFilters.transferNumber.value = (this.productTransferNumber) ? [this.productTransferNumber.replaceAll(' ', '')] : null
  }

  @Watch('transactionTransferNumber') onTransitionTransferNumberUpdate() {
    this.transactionFilters.transferNumber.value = (this.transactionTransferNumber) ? this.transactionTransferNumber.replaceAll(' ', '') : null
  }

  @Watch('productAccountNumber') onProductAccountNumberUpdate() {
    this.productFilters.accountNumber.value = (this.productAccountNumber) ? this.productAccountNumber.replaceAll(' ', '') : null
  }

  @Watch('transactionAccountNumber') onTransactionAccountNumberUpdate() {
    this.transactionFilters.counterpartyAccountNumber.value = (this.transactionAccountNumber) ? this.transactionAccountNumber.replaceAll(' ', '') : null
  }

  mounted() {
    this.loadFiltersFromStore();
  }
}
