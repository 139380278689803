var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "statement-products-table"
  }, [_c('sygni-container-title', [_vm._v("Products")]), _c('div', {
    staticClass: "statement-products-table__box"
  }, [_c('simplebar', {
    ref: "tableInner",
    staticClass: "statement-products-table__inner"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "statementProductsTable",
    staticClass: "sygni-b-inner-table sygni-b-table",
    attrs: {
      "innertable": "true",
      "no-border-collapse": "",
      "tbody-tr-class": _vm.rowClass,
      "fields": _vm.tableFields,
      "items": _vm.items,
      "show-empty": true
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(selected)",
      fn: function fn(rowData) {
        var _vm$bulkRows$rowData$;

        return [_c('div', {
          staticClass: "table-checkbox"
        }, [_c('sygni-radio', {
          staticClass: "secondary",
          attrs: {
            "radioValue": (_vm$bulkRows$rowData$ = _vm.bulkRows[rowData.index]) === null || _vm$bulkRows$rowData$ === void 0 ? void 0 : _vm$bulkRows$rowData$.label
          },
          model: {
            value: _vm.selectedProductId,
            callback: function callback($$v) {
              _vm.selectedProductId = $$v;
            },
            expression: "selectedProductId"
          }
        })], 1)];
      }
    }, {
      key: "cell(transferNumber)",
      fn: function fn(rowData) {
        var _rowData$item, _rowData$item$transfe, _rowData$item2, _rowData$item2$transf;

        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              title: _vm.getTooltipMessage((_rowData$item = rowData.item) === null || _rowData$item === void 0 ? void 0 : (_rowData$item$transfe = _rowData$item.transferNumber) === null || _rowData$item$transfe === void 0 ? void 0 : _rowData$item$transfe.join(', '), 20),
              boundary: 'viewport'
            },
            expression: "{ title: getTooltipMessage(rowData.item?.transferNumber?.join(', '), 20), boundary: 'viewport' }",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap text-left"
        }, [_vm._v(_vm._s(_vm._f("trimString")((_rowData$item2 = rowData.item) === null || _rowData$item2 === void 0 ? void 0 : (_rowData$item2$transf = _rowData$item2.transferNumber) === null || _rowData$item2$transf === void 0 ? void 0 : _rowData$item2$transf.join(', '), 20)))])];
      }
    }, {
      key: "cell(code)",
      fn: function fn(rowData) {
        var _rowData$item3, _rowData$item4;

        return [_c('a', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              title: _vm.getTooltipMessage(rowData.item.code, 20),
              boundary: 'viewport'
            },
            expression: "{ title: getTooltipMessage(rowData.item.code, 20), boundary: 'viewport' }",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap text-left hoverable-cell",
          attrs: {
            "href": _vm.getProductSummaryLink(rowData === null || rowData === void 0 ? void 0 : (_rowData$item3 = rowData.item) === null || _rowData$item3 === void 0 ? void 0 : _rowData$item3.productId),
            "target": "_blank"
          }
        }, [_vm._v(_vm._s(_vm._f("trimString")((_rowData$item4 = rowData.item) !== null && _rowData$item4 !== void 0 && _rowData$item4.code ? rowData.item.code : '', 20)))])];
      }
    }, {
      key: "cell(date)",
      fn: function fn(rowData) {
        var _rowData$item5;

        return [_c('div', {
          staticClass: "source-name text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("dateWithoutTime")((_rowData$item5 = rowData.item) === null || _rowData$item5 === void 0 ? void 0 : _rowData$item5.date)) + " ")])];
      }
    }, {
      key: "cell(investorName)",
      fn: function fn(rowData) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              title: _vm.getTooltipMessage(rowData.item.investorName, 50),
              boundary: 'viewport'
            },
            expression: "{ title: getTooltipMessage(rowData.item.investorName, 50), boundary: 'viewport' }",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap text-left"
        }, [_vm._v(" " + _vm._s(_vm._f("trimString")(rowData.item.investorName, 50)) + " ")])];
      }
    }, {
      key: "cell(accountNumber)",
      fn: function fn(rowData) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              title: _vm.getTooltipMessage(rowData.item.accountNumber, 40),
              boundary: 'viewport'
            },
            expression: "{ title: getTooltipMessage(rowData.item.accountNumber, 40), boundary: 'viewport' }",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap text-left"
        }, [_vm._v(" " + _vm._s(_vm._f("trimString")(rowData.item.accountNumber, 40)) + " ")])];
      }
    }, {
      key: "cell(value)",
      fn: function fn(rowData) {
        var _rowData$item6;

        return [_c('div', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item6 = rowData.item) === null || _rowData$item6 === void 0 ? void 0 : _rowData$item6.value)) + " ")])];
      }
    }, {
      key: "cell(currency)",
      fn: function fn(rowData) {
        var _rowData$item7, _rowData$item7$curren;

        return [_c('div', {
          staticClass: "text-nowrap text-left"
        }, [_vm._v(" " + _vm._s((_rowData$item7 = rowData.item) === null || _rowData$item7 === void 0 ? void 0 : (_rowData$item7$curren = _rowData$item7.currency) === null || _rowData$item7$curren === void 0 ? void 0 : _rowData$item7$curren.toUpperCase()) + " ")])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(rowData) {
        var _rowData$item8, _rowData$item8$type;

        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s((_rowData$item8 = rowData.item) === null || _rowData$item8 === void 0 ? void 0 : (_rowData$item8$type = _rowData$item8.type) === null || _rowData$item8$type === void 0 ? void 0 : _rowData$item8$type.toUpperCase()) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn() {
        return [_c('div', {
          staticClass: "action"
        }, [_c('img', {
          staticClass: "rotate-270",
          attrs: {
            "src": _vm._f("getIcon")('ARROW'),
            "alt": ""
          }
        })])];
      },
      proxy: true
    }, {
      key: "cell(paymentStatus)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "payment-status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          class: "outline round ".concat(_vm.paymentStatusClass(rowData.item.paymentStatus)),
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(" " + _vm._s(_vm.paymentStatusText(rowData.item.paymentStatus)) + " ")])], 1)];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }