









































































































































































































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import SygniModal from "@/components/layout/SygniModal.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import {BSpinner, BTable, BvTableField} from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import InvestorsInnerTable from "@/modules/genprox/modules/fund/modules/fundraising/components/InvestorsInnerTable.vue";
import { CheckboxOption } from '@/store/types';
import { Prop, Watch } from 'vue-property-decorator';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { TableQuery } from '@/modules/shared/utils/TableQuery';
import SygniPagination from '@/components/table/SygniPagination.vue';
import SygniFlagPicker from '@/components/inputs/SygniFlagPicker.vue';
import SygniRadio from '@/components/inputs/SygniRadio.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniDatePicker from '@/components/inputs/SygniDatePicker.vue';
import Vue from 'vue';
import { create, all } from 'mathjs'
import { StatementItem } from '../store/types';
import _ from 'lodash';

const math = create(all);

Component.registerHooks(['validations'])
@Component({
  components: {
    SygniCircleButton,
    InvestorsInnerTable, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, SygniSelect, SygniCheckbox, SygniTextArea, SygniSquareButton, SygniRectButton, SygniModal, SygniFlagPicker, SygniRadio, SygniInput, SygniDatePicker, SygniLoader },
})
export default class StatementTable extends SygniTable<any> {
  math: any = math;
  isSepa: boolean = false;
  bulkRows: Array<CheckboxOption> = [];
  selectAllRowsBoolean: boolean | null = false;
  bulkOptionsMarginTop: number = 0;
  showBankAccountModal: boolean = false;
  isPaymentPackageLoading: boolean = false;
  selectedBankAccount: any = null;
  paymentForSingleDocument: any = false;
  showRejectionModal: boolean = false;
  isRejectionModalLoading: boolean = false;
  useCustomPaymentDate: boolean = false;
  customPaymentDate: string = '';
  customPackageName: string = '';
  rejectionNote: string = '';
  refresh: any = null;
  bulkPaymentStatus: string = '';
  bulkPaymentStatusPlaceholder: string = 'Select option';
  isAccountingDateLoading: boolean = false;
  isStatusLoading: boolean = false;
  statementsDateOpened: boolean = false;
  statementsDate: string = '';
  @Prop({ default: true }) hideAdditionalColumns: boolean;

  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    { key: 'selected', sortable: false, borderless: true, label: ''},
    { key: 'fullNumber', sortable: true, class: 'text-left', label: 'Report No.'},
    { key: 'accountNumber', sortable: true, class: 'text-left', label: 'Account No.'},
    { key: 'issueDate', class: 'text-left', sortable: true, borderless: true, label: 'Op. Date' },
    { key: 'closeDate', class: 'text-left', sortable: true, borderless: true, label: 'Cl. Date' },
    { key: 'balance', class: 'text-right', sortable: true, borderless: true, label: 'Op. Balance Ccy' },
    { key: 'movements', class: 'text-right', sortable: true, borderless: true, label: 'Turnover Ccy' },
    { key: 'revenue', class: 'text-right', sortable: true, borderless: true, label: 'Inflows Ccy' },
    { key: 'expenditure', class: 'text-right', sortable: true, borderless: true, label: 'Outflows Ccy' },
    { key: 'closingBalance', class: 'text-right', sortable: true, borderless: true, label: 'Cl. Balance Ccy' },
    { key: 'systemBalance', class: 'hide text-right', tdClass: 'hide', sortable: true, borderless: true, label: 'Op. Balance PLN' },
    { key: 'systemMovements', class: 'hide text-right', tdClass: 'hide', sortable: true, borderless: true, label: 'Turnover PLN' },
    { key: 'systemRevenue', class: 'hide text-right', tdClass: 'hide', sortable: true, borderless: true, label: 'Inflows PLN' },
    { key: 'systemExpenditure', class: 'hide text-right', tdClass: 'hide', sortable: true, borderless: true, label: 'Outflows PLN' },
    { key: 'systemClosingBalance', class: 'hide text-right', tdClass: 'hide', sortable: true, borderless: true, label: 'Cl. Balance PLN' },
    { key: 'currency', class: 'text-center', sortable: true, borderless: true, label: 'Report Ccy' },
    { key: 'status', sortable: true, label: 'Status' },
    { key: 'actions', sortable: false, label: 'Actions' },
  ]

  getCurrencyLabel(currency: string | null) {
    return currency ? currency?.toUpperCase() : 'PLN'
  }

  getActions(item: StatementItem) {
    const actions = ['preview']

    if (item.status !== 'in-progress') {
      actions.push('set-in-progress')
    }

    if (item.status !== 'closed') {
      actions.push('set-closed')
    }

    return actions
  }

  hasAction(action: string, item: StatementItem) {
    return this.getActions(item).includes(action)
  }

  getActionMessage(action: string) {
    switch (action) {
      case 'preview':
        return 'Show bank transactions'
      case 'set-in-progress':
        return 'Mark as in progress'
      case 'set-closed':
        return 'Mark as approved'
      default:
        return this.$options.filters.snakeCaseToTitleCase(action)
    }
  }

  getActionIcon(action: string) {
    switch (action) {
      case 'preview':
        return this.$options.filters.getIcon('Fund')
      case 'set-in-progress':
        return this.$options.filters.getIcon('Insights')
      case 'set-closed':
        return this.$options.filters.getIcon('APPROVE 2')
      default:
        return this.$options.filters.getIcon('Fund')
    }
  }

  async handleRowAction(action: string, id: string) {
    this.$store.commit(this.setTableBusyMutation, true);

    switch (action) {
      case 'preview':
        this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/bank-statement/${id}` });
        break;
      case 'set-in-progress':
      case 'set-closed':
        try {
          const actionName = action === 'set-in-progress' ? 'setInProgressBankStatement' : 'setClosedBankStatement'
          await this.$store.dispatch(`statements/${actionName}`, id)
          await this.refreshTable()

          this.$notify({
            type: 'success',
            title: 'Success',
            text: 'Status changed successfully'
          })

        } catch (e) {
          const errorMessage = this.$options.filters.errorHandler(e)
          this.$notify({
            duration: 2500,
            type: 'error',
            title: 'Error',
            text: errorMessage
          })
        }
        break;
      default:
        break;
    }

    this.$store.commit(this.setTableBusyMutation, false);
  }

  get allActions() {
    let actions: string[] = []

    this.items.forEach((item: StatementItem) => {
      actions = actions.concat(this.getActions(item))
    })

    return this.allActionItems.filter((action: string) => _.uniq(actions).includes(action))
  }

  get allActionItems() {
    return ['preview', 'set-in-progress', 'set-closed']
  }

  get setInProgressEnabled() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
    let onlyValid = true;

    if (selectedRowsIds.length) {
      selectedRowsIds.forEach((id: any) => {
        const item = this.items.find(el => el.id == id);
        if (item && item.status === 'in-progress') {
          onlyValid = false;
        }
      });

      return onlyValid;
    }

    return false;
  }

  get setClosedEnabled() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
    let onlyValid = true;

    if (selectedRowsIds.length) {
      selectedRowsIds.forEach((id: any) => {
        const item = this.items.find(el => el.id == id);
        if (item && item.status === 'closed') {
          onlyValid = false;
        }
      });

      return onlyValid;
    }

    return false;
  }

  async bulkSetStatus(status: 'in-progress' | 'closed') {
    this.isStatusLoading = true

    try {
      const action = (status === 'in-progress') ? 'bulkSetInProgressBankStatement' : 'bulkSetClosedBankStatement';
      const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
      await this.$store.dispatch(`statements/${action}`, selectedRowsIds)

      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Statuses changed successfully'
      })

    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isStatusLoading = false
  }

  onFiltersChange(filtersQuery?: string): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$store.commit('statements/setStatementsTableFiltersQuery', filtersQuery);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch(this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit('statements/setStatementsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch(this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit('statements/setStatementsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  async refreshTable() {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$nextTick(async () => {
      await this.getItems();
    })
  }

  toggleAllRowsAction() {
    if(this.selectAllRowsBoolean === null) return;
    this.selectAllRowsBoolean = !this.selectAllRowsBoolean;

    if(this.selectAllRowsBoolean) {
      const selectedRowEl = (this.$refs.statementsTable as BTable).$el.querySelector('.table tbody tr:nth-of-type(1)');
      this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
      (this.$refs.statementsTable as BTable).selectAllRows();
    } else {
      (this.$refs.statementsTable as BTable).clearSelected();
    }

    this.bulkRows.forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  selectRowEl(index: number) {
    if(this.$refs.statementsTable == undefined) return;

    this.deselectRowEl();
    const activeRow = ((this.$refs.statementsTable as StatementTable).$el as HTMLElement).querySelector(`tbody tr[aria-rowindex="${index + 1}"]`);
    this.$scrollTo(activeRow);
    activeRow.classList.add('active');
  }

  deselectRowEl() {
    if(this.$refs.statementsTable == undefined) return;

    const rows = ((this.$refs.statementsTable as StatementTable).$el as HTMLElement).querySelectorAll(`tbody tr`);
    rows.forEach(row => row.classList.remove('active'));
  }

  get statementsTableQuery() {
    return this.$store.getters['statements/getStatementsTableQuery'];
  }

  toggleTableRow(item: any, index: number) {
    const selectedRowEl = (this.$refs.statementsTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(${index + 1})`);
    this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
    if(this.bulkRows[index]) {
      this.bulkRows[index].value = !this.bulkRows[index].value;
    }

    if(item.value) {
      (this.$refs.statementsTable as BTable).selectRow(index);
    } else {
      (this.$refs.statementsTable as BTable).unselectRow(index);
    }
  }

  clearTableSelection() {
    this.selectAllRowsBoolean = false;
    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = false;
    });
  }

  get numberOfPages() {
    return this.paginationInstance?.pageSizeNumberOfPages?.numberOfPages;
  }

  setQuery() {
    this.$store.commit('statements/setStatementsTableQuery', this.localTableQuery);
  }

  async getItems() {
    await this.$store.dispatch('statements/getStatements', this.filtersQuery);
    this.resetCheckboxes();
  }

  resetCheckboxes(): void {
    this.bulkRows = [];
    this.items.forEach((el, index) => {
      this.$set(this.bulkRows, index, { label: el.id, value: false });
    });
  }

  statusText(status: string) {
    switch (status?.toLowerCase()) {
      case 'new':
        return 'New'
      case 'closed':
        return 'Approved'
      default:
        return this.$options.filters.snakeCaseToTitleCase(status)
    }
  }

  statusClassName(status: string) {
    switch (status?.toLowerCase()) {
      case 'new':
        return 'gray'
      case 'synchronized':
        return 'warning';
      case 'in-progress':
        return 'success'
      case 'closed':
        return 'primary'
      default:
        return status
    }
  }

  get filtersQuery() {
    return this.$store.getters['statements/getStatementsTableFiltersQuery'];
  }

  get rowsSelected() {
    const selectedRows = this.bulkRows.filter(el => el.value);

    return selectedRows;
  }

  get selectedRowsLength() {
    const selectedRows = this.bulkRows.filter(el => el.value);

    return selectedRows?.length;
  }

  get balanceTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const currency = item?.currency || 'PLN'
      if(currency) {
        if(totals[currency]) {
          totals[currency] = item.balance ? math.number(math.add(math.bignumber(totals[currency]), math.bignumber(item.balance))) : math.number(math.add(math.bignumber(totals[currency]), 0));
        } else {
          totals[currency] = item.balance ? math.bignumber(math.bignumber(item.balance)) : 0;
        }
      }
    })

    return totals;
  }

  get systemBalanceTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const currency = item?.currency || 'PLN'
      if (currency) {
        if (totals[currency]) {
          totals[currency] = item.systemBalance ? math.number(math.add(math.bignumber(totals[currency]), math.bignumber(item.systemBalance))) : math.number(math.add(math.bignumber(totals[currency]), 0));
        } else {
          totals[currency] = item.systemBalance ? math.bignumber(math.bignumber(item.systemBalance)) : 0;
        }
      }
    })

    return totals;
  }

  get movementTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const currency = item?.currency || 'PLN'
      if(currency) {
        if(totals[currency]) {
          totals[currency] = item.movements ? math.number(math.add(math.bignumber(totals[currency]), math.bignumber(item.movements))) : math.number(math.add(math.bignumber(totals[currency]), 0));
        } else {
          totals[currency] = item.movements ? math.bignumber(math.bignumber(item.movements)) : 0;
        }
      }
    })

    return totals;
  }

  get systemMovementTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const currency = item?.currency || 'PLN'
      if(currency) {
        if(totals[currency]) {
          totals[currency] = item.systemMovements ? math.number(math.add(math.bignumber(totals[currency]), math.bignumber(item.systemMovements))) : math.number(math.add(math.bignumber(totals[currency]), 0));
        } else {
          totals[currency] = item.systemMovements ? math.bignumber(math.bignumber(item.systemMovements)) : 0;
        }
      }
    })

    return totals;
  }

  get revenueTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const currency = item?.currency || 'PLN'
      if(currency) {
        if(totals[currency]) {
          totals[currency] = item.revenue ? math.number(math.add(math.bignumber(totals[currency]), math.bignumber(item.revenue))) : math.number(math.add(math.bignumber(totals[currency]), 0));
        } else {
          totals[currency] = item.revenue ? math.bignumber(math.bignumber(item.revenue)) : 0;
        }
      }
    })

    return totals;
  }

  get systemRevenueTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const currency = item?.currency || 'PLN'
      if(currency) {
        if(totals[currency]) {
          totals[currency] = item.systemRevenue ? math.number(math.add(math.bignumber(totals[currency]), math.bignumber(item.systemRevenue))) : math.number(math.add(math.bignumber(totals[currency]), 0));
        } else {
          totals[currency] = item.systemRevenue ? math.bignumber(math.bignumber(item.systemRevenue)) : 0;
        }
      }
    })

    return totals;
  }

  get expenditureTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const currency = item?.currency || 'PLN'
      if(currency) {
        if(totals[currency]) {
          totals[currency] = item.expenditure ? math.number(math.add(math.bignumber(totals[currency]), math.bignumber(item.expenditure))) : math.number(math.add(math.bignumber(totals[currency]), 0));
        } else {
          totals[currency] = item.expenditure ? math.bignumber(math.bignumber(item.expenditure)) : 0;
        }
      }
    })

    return totals;
  }

  get systemExpenditureTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const currency = item?.currency || 'PLN'
      if(currency) {
        if(totals[currency]) {
          totals[currency] = item.systemExpenditure ? math.number(math.add(math.bignumber(totals[currency]), math.bignumber(item.systemExpenditure))) : math.number(math.add(math.bignumber(totals[currency]), 0));
        } else {
          totals[currency] = item.systemExpenditure ? math.bignumber(math.bignumber(item.systemExpenditure)) : 0;
        }
      }
    })

    return totals;
  }

  get closingBalanceTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const currency = item?.currency || 'PLN'
      if(currency) {
        if(totals[currency]) {
          totals[currency] = item.closingBalance ? math.number(math.add(math.bignumber(totals[currency]), math.bignumber(item.closingBalance))) : math.number(math.add(math.bignumber(totals[currency]), 0));
        } else {
          totals[currency] = item.closingBalance ? math.bignumber(math.bignumber(item.closingBalance)) : 0;
        }
      }
    })

    return totals;
  }
  get systemClosingBalanceTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const currency = item?.currency || 'PLN'
      if(currency) {
        if(totals[currency]) {
          totals[currency] = item.systemClosingBalance ? math.number(math.add(math.bignumber(totals[currency]), math.bignumber(item.systemClosingBalance))) : math.number(math.add(math.bignumber(totals[currency]), 0));
        } else {
          totals[currency] = item.systemClosingBalance ? math.bignumber(math.bignumber(item.systemClosingBalance)) : 0;
        }
      }
    })

    return totals;
  }

  get selectedTotals() {
    let totals: any = {};
    const selectedRows = this.rowsSelected.map(el => {
      el = this.items.find(row => el.label == row.id);
      return el;
    });

    selectedRows.forEach((item: any) => {
      if (item.currency) {
        if (totals[item.currency]) {
          totals[item.currency] = item.grossAmount ? math.number(math.add(math.bignumber(totals[item.currency]), math.bignumber(item.grossAmount))) : math.number(math.add(math.bignumber(totals[item.currency]), 0));
        } else {
          totals[item.currency] = item.grossAmount ? math.bignumber(math.bignumber(item.grossAmount)) : 0;
        }
      }
    })

    return totals;
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  validations () {
    return  {
      selectedBankAccount: { required },
      customPaymentDate: { required: requiredIf(() => this.useCustomPaymentDate) }
    }
  }

  async beforeMount() {
    await this.getItems();
    this.onMounted();
    this.setBorderlessTds();
  }

  addPagination(): void {
    const ComponentClass = Vue.extend(SygniPagination);
    this.paginationInstance = new ComponentClass<SygniPagination>();

    const currentPage = this.statementsTableQuery.offset / this.statementsTableQuery.limit + 1;

    this.paginationInstance.showPageNumberOptions = this.showPageNumberOptions;
    this.paginationInstance.perPage = this.tableData.perPage;
    this.paginationInstance.totalRows = this.tableData.totalCount;
    this.paginationInstance.currentPage = currentPage;
    this.paginationInstance.$mount();
    this.paginationInstance.$on('change', (currentPage: number) => {
      this.$store.commit(this.setTableBusyMutation, true);
      this.localTableQuery = new TableQuery().setQuery(this.tableData.query);
      this.localTableQuery.limit = this.tableData.perPage;
      this.localTableQuery.offset = this.tableData.perPage * (currentPage - 1);
      this.setQuery();
      this.getItems();
      this.$scrollTo(((this.$refs.statementsTable as StatementTable).$el as HTMLElement));
    });
    this.paginationInstance.$on('changePerPageOption', (perPage: number) => {
      if(this.tablePerPageMutation) this.$store.commit(this.tablePerPageMutation, perPage);
      this.$store.commit(this.setTableBusyMutation, true);
      this.localTableQuery = new TableQuery().setQuery(this.tableData.query);
      this.localTableQuery.limit = this.tableData.perPage;
      this.localTableQuery.offset = 0;
      this.setQuery();
      this.getItems();
      this.changePerPageOption();
    });

    this.$nextTick(() => {
      this.paginationInstance.$emit('changePage', currentPage)
    })

    const tableElement: Element = this.$el.querySelector('.sygni-b-table') as Element;

    const isStatementTable = this.$el.classList.contains('statement-table');
    const isWhistleblowerTable = this.$el.classList.contains('whistleblower-table');
    const isPortfolioTable = this.$el.classList.contains('portfolio-table');

    if(isStatementTable) {
      this.$el.querySelector('.statement-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else if(isWhistleblowerTable) {
      this.$el.querySelector('.whistleblower-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else if(isPortfolioTable) {
      this.$el.querySelector('.portfolio-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else {
      this.$el.insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    }
  }

  @Watch('selectAllRowsBoolean') onSelectAllRowsBooleanChange(): void {
    const selectedRowEl = (this.$refs.statementsTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(1)`);
    this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;

    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  @Watch('rowsSelected') onRowsSelectedChange(): void {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
    let onlySamePaymentStatuses = true;
    let lastPaymentStatus = '';

    if(selectedRowsIds.length) {
      selectedRowsIds.forEach((id: any, index: number) => {
        const item = this.items.find(el => el.id == id);
        if(index != 0) {
          if(item.paymentStatus !== lastPaymentStatus) {
            onlySamePaymentStatuses = false;
          }
        }
        lastPaymentStatus = item.paymentStatus;
      });
    }

    this.bulkPaymentStatusPlaceholder = onlySamePaymentStatuses ? this.$options.filters.capitalizeFirstLetter(lastPaymentStatus) : 'Select option';
    this.bulkPaymentStatus = '';
  }
}

