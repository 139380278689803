
















































import Vue from 'vue'
import Component from 'vue-class-component'
import StatementFilters from "@/modules/statements/components/StatementFilters.vue";
import ReconciliationFilters from "@/modules/statements/components/ReconciliationFilters.vue";
import StatementTable from "@/modules/statements/components/StatementTable.vue";
import {TableData} from "@/modules/genprox/modules/profile/store/types";
import {mapState} from "vuex";
import { StatementItem, StatementProductItem, StatementTransactionItem } from '@/modules/statements/store/types';
import { BTable } from 'bootstrap-vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import StatementTransactionsTable from '@/modules/statements/components/StatementTransactionsTable.vue';
import StatementProductsTable from '@/modules/statements/components/StatementProductsTable.vue';
import SygniTabs from '@/components/layout/SygniTabs.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

@Component({
  components: { StatementFilters, ReconciliationFilters, StatementTable, StatementTransactionsTable, SygniRectButton, StatementProductsTable, BTable, SygniRoundedButton, SygniContainerTitle, SygniTabs, SygniLoader, SygniModal, SygniLinkButton, simplebar },
  computed: mapState('statements', {
    // statements
    statementsTable: (state: any) => state.statementsTable,
    statementsAreBusy: (state: any) => state.statementsTableIsBusy,
    statementsPerPage: (state: any) => state.statementsTable.perPage,
    // statement products
    statementProductsTable: (state: any) => state.statementProductsTable,
    statementProductsAreBusy: (state: any) => state.statementProductsTableIsBusy,
    statementProductsPerPage: (state: any) => state.statementProductsTable.perPage,
    // statement transactions
    statementTransactionsTable: (state: any) => state.statementTransactionsTable,
    statementTransactionsAreBusy: (state: any) => state.statementTransactionsTableIsBusy,
    statementTransactionsPerPage: (state: any) => state.statementTransactionsTable.perPage,
  })
})
export default class statementModule extends Vue {
  readonly supportedFileFormats: Array<string> = ['pdf', 'jpeg', 'jpg', 'png'];
  tempFile: string = "";
  // statements
  statementsTable!: TableData<StatementItem>;
  statementsPerPage!: number;
  statementsAreBusy!: boolean;
  // statement products
  statementProductsTable!: TableData<StatementProductItem>;
  statementProductsPerPage!: number;
  statementProductsAreBusy!: boolean;
  // statement transactions
  statementTransactionsTable!: TableData<StatementTransactionItem>;
  statementTransactionsPerPage!: number;
  statementTransactionsAreBusy!: boolean;
  // other
  filtersQueryString: string = '';
  filtersQueryStrings: string[] = []
  isLoading: boolean = false;
  hideAdditionalColumns: boolean = true;
  syncInfo: { new: any[], updated: any[] } = null
  defaultTabOption: string = 'bank-statements';
  selectedTab: string = 'bank-statements';
  productObject: { objectId: string, objectType: string } = null
  tabOptions: any = [
    {
      label: 'Bank statements',
      key: 'bank-statements'
    },
    {
      label: 'Reconciliations',
      key: 'reconciliations'
    },
  ];

  handleProductSelection(objectData: { objectId: string, objectType: string, data: any }) {
    if (this.productObject) {
      (this.$refs.statementTransactionsTable as StatementTransactionsTable)?.resetCheckboxes()
    }
    this.productObject = objectData;
    this.$nextTick(() => {
      (this.$refs.statementTransactionsTable as StatementTransactionsTable)?.getItems()
    })
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  selectTab(value: string) {
    this.selectedTab = value;

    if (value === 'reconciliations') {
      this.$router.push({ query: { page: value } });
    } else {
      this.$router.push({ query: null })
    }
  }
  
  getReportStatusClass(status: string) {
    let className = 'primary';

    if (status == 'updated') {
      className = 'success';
    }

    return className;
  }

  getReportStatusText(status: string) {
    return this.$options.filters.snakeCaseToTitleCase(status)
  }

  get syncInfoItems() {
    const items: any[] = []

    this.syncInfo?.new?.forEach((el: any) => {
      items.push({ ...el, status: 'new' })
    })

    this.syncInfo?.updated?.forEach((el: any) => {
      items.push({ ...el, status: 'updated' })
    })

    return items
  }

  get toggleColumnsText() {
    return this.hideAdditionalColumns ? 'Show extra columns' : 'Hide extra columns'
  }

  openReport(id: string) {
    if (id) {
      this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/bank-statement/${id}` });
    }
  }

  closeSyncInfoModal() {
    this.syncInfo = null
  }

  toggleAdditionalColumns() {
    this.hideAdditionalColumns = !this.hideAdditionalColumns;
  }

  async synchronizeReports() {
    this.isLoading = true

    try {
      const syncInfo: any = await this.$store.dispatch('statements/patchBankStatements')
      if (syncInfo?.new?.length || syncInfo?.updated?.length) {
        const statementIds: string[] = syncInfo?.new?.map((el: any) => el.id)

        if (statementIds?.length) {
          const promises: any[] = []
          for (const statementId of statementIds) {
            promises.push(this.$store.dispatch('statements/patchBankTransactions', statementId))
          }
  
          await Promise.all(promises)
        }

        this.syncInfo = syncInfo;
      } else {
        this.syncInfo = null
        this.$notify({
          type: 'success',
          title: 'Success',
          duration: 5000,
          text: 'Reports synchronized successfully but there were no new or updated reports'
        });
      }
      await (this.$refs.statementTable as StatementTable).refreshTable()
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isLoading = false
  }

  applyFiltersEvent(queryString: string) {
    this.filtersQueryString = queryString;

    if (this.selectedTab === 'bank-statements') {
      (this.$refs.statementTable as StatementTable).onFiltersChange(queryString);
    } else {
      // reconciliations
      const type = (this.$refs.statementFilters as ReconciliationFilters)?.tabOption

      if (type === 'filter-transactions') {
        (this.$refs.statementTransactionsTable as StatementTransactionsTable).onFiltersChange(this.filtersQueryStrings[1] || '')
      } else {
        (this.$refs.statementProductsTable as StatementProductsTable).onFiltersChange(this.filtersQueryStrings[0] || '')
      }
    }
  }

  applyReconciliationFiltersEvent(queryStrings: string[]) {
    this.filtersQueryStrings = queryStrings;

    (this.$refs.statementTransactionsTable as StatementTransactionsTable).onFiltersChange(queryStrings[1]);
    (this.$refs.statementProductsTable as StatementProductsTable).onFiltersChange(queryStrings[0])
  }
  
  refreshTables() {
    setTimeout(() => {
      (this.$refs.statementTransactionsTable as StatementTransactionsTable).onFiltersChange(this.filtersQueryStrings[1] || '');
      (this.$refs.statementProductsTable as StatementProductsTable).onFiltersChange(this.filtersQueryStrings[0] || '');
    }, 500)
  }
  
  scrollToSection(type: string) {
    if (type === 'filter-transactions') {
      (this as any).$scrollTo(((this.$refs.statementTransactionsTable as StatementTransactionsTable)?.$el as HTMLElement));
    } else if (type === 'filter-products') {
      (this as any).$scrollTo(((this.$refs.statementProductsTable as StatementProductsTable)?.$el as HTMLElement));
    }
  }

  mounted() {
    if (this.$route.query?.page) {
      this.selectedTab = this.$route.query.page.toString();
      (this.$refs.tabs as SygniTabs).setOption(this.selectedTab);
    }
  }
}
